require('./plugins/contentful-paint');

document.addEventListener('DOMContentLoaded', function () {
    // common
    require('./common');

    // if (document.querySelector('main.template-home')) {
    //     import('./pages/home.js');
    // }
    // if (document.querySelector('main.template-page-about')) {
    //     import('./pages/about.js');
    // }
});
